<script setup>
import { ref, computed, onMounted, reactive } from "vue";
const props = defineProps({
  label: { type: String, default: "button" },
  loading: { type: Boolean },
  disabled: { type: Boolean },
  textColor: { type: String, default: "" },
  bgColor: { type: String, default: "" },
  design: { type: String, default: "filled" },
  size: { type: String, default: "medium" },
  state: { type: String, default: "primary" },
  iconLeft: { type: String },
  iconRight: { type: String },
  iconHeight: { type: String },
  iconWidth: { type: String },
  icon: { type: String },
  href: { type: String },
});

const buttonElement = ref();
let iconHeight = ref();

onMounted(() => {
  if (props.href) {
    const anchorElement = document.createElement("a");

    for (const { name, value } of buttonElement.value.attributes) {
      anchorElement.setAttribute(name, value);
    }

    anchorElement.innerHTML = buttonElement.value.innerHTML;
    anchorElement.href = props.href;

    buttonElement.value.parentNode.replaceChild(
      anchorElement,
      buttonElement.value
    );
  }
});

// computing loader color from given type attribute
const loaderColor = computed(() => {
  if (props.design == "filled") {
    return "#ffffff";
  } else {
    var style = getComputedStyle(document.documentElement);
    return style
      .getPropertyValue(`--color-${props.state}-700-aaa-colorful`)
      .trim();
  }
});

// computing button class from given types and states
const buttonClasses = computed(() => {
  const classes = [""];

  if (props.state) {
    classes.push(props.state);
  }
  if (props.design) {
    classes.push(props.design);
  }
  return classes.join(" ");
});
</script>

<template>
  <button
    v-if="!props.icon"
    id="chi-button"
    ref="buttonElement"
    :disabled="props.disabled"
    :style="`color: ${props.textColor}; background-color: ${props.bgColor}`"
    :class="[
      buttonClasses,
      props.size == 'large'
        ? 'text-style-tokens-button-chip-16-medium large'
        : props.size == 'medium'
        ? 'text-style-tokens-button-chip-16-medium medium'
        : props.size == 'small'
        ? 'text-style-tokens-button-chip-14-medium small'
        : '',
      props.loading ? 'loading' : '',
    ]"
    class="chi-button text-btn"
  >
    <img
      :class="[
        props.size == 'large'
          ? 'text-icon-medium'
          : props.size == 'medium'
          ? 'text-icon-medium'
          : props.size == 'small'
          ? 'text-icon-small'
          : '',
      ]"
      v-if="props.iconLeft"
      :src="props.iconLeft"
      :style="{
        width: props.iconWidth ? props.iconWidth : '',
        height: props.iconHeight ? props.iconHeight : iconHeight,
        visibility: !props.loading && props.iconLeft ? '' : 'hidden',
      }"
    />
    <span :style="!props.loading && !props.icon ? '' : 'visibility:hidden'">
      {{ props.label }}</span
    >
    <BaseCustomLoader
      v-if="props.loading"
      :loaderColor="loaderColor"
    ></BaseCustomLoader>
    <img
      :class="[
        props.size == 'large'
          ? 'text-icon-medium'
          : props.size == 'medium'
          ? 'text-icon-medium'
          : props.size == 'small'
          ? 'text-icon-small'
          : '',
      ]"
      v-if="props.iconRight"
      :src="props.iconRight"
      :style="{
        width: props.iconWidth ? props.iconWidth : '',
        height: props.iconHeight ? props.iconHeight : iconHeight,
        visibility: !props.loading && props.iconRight ? '' : 'hidden',
      }"
    />
  </button>
  <button
    v-else
    id="chi-button"
    ref="buttonElement"
    :disabled="props.disabled"
    :style="`color: ${props.textColor}; background-color: ${props.bgColor}`"
    :class="[
      props.size == 'large'
        ? 'icon-large'
        : props.size == 'medium'
        ? 'icon-medium'
        : props.size == 'small'
        ? 'icon-small'
        : '',
      buttonClasses,
      props.loading ? 'loading' : '',
    ]"
    class="chi-button icon-btn"
  >
    <img
      class="icon"
      v-if="props.icon"
      :src="props.icon"
      :style="{
        width: props.iconWidth ? props.iconWidth : '',
        height: props.iconHeight ? props.iconHeight : iconHeight,
        visibility: !props.loading && props.icon ? '' : 'hidden',
      }"
    />
    <slot />

    <BaseCustomLoader
      v-if="props.loading"
      :loaderColor="loaderColor"
    ></BaseCustomLoader>
  </button>
</template>
<style scoped lang="scss">
// .icon {
//   filter: brightness(0) saturate(100%) invert(48%) sepia(94%) saturate(1512%)
//     hue-rotate(157deg) brightness(89%) contrast(106%);
// }
$types: primary, critical, promote, warning, neutral, positive;

@each $type in $types {
  .chi-button.#{$type} {
    border-radius: 8px;
    outline: none;
    border: none;
    transition: background-color 0.2s cubic-bezier(0.2, 0, 0, 1);
    width: fit-content;
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &.loading {
      pointer-events: none;
    }
    // &:focus-visible {
    //   outline: 4px solid black;
    // }
    &.text-btn {
      padding: 8px 16px;
    }
    &.icon-btn {
      height: fit-content;
    }
    span {
      padding: 0px 8px;
    }

    &.neutral {
      &.filled {
        background-color: var(--color-neutral-600-aa-text);
        color: var(--color-f);
        &:hover {
          background-color: var(--color-neutral-button-filled-hover);
        }
        &:active {
          background-color: var(--color-neutral-button-filled-press);
        }
        &:disabled {
          background: var(--color-neutral-200-darker-bg-light-border);
          color: var(--color-neutral-400);
          cursor: default;
        }
      }
      &.outlined {
        border: 1px solid var(--color-neutral-300-border, #d3dfe7);
        color: var(--color-neutral-900-aaa-text-darkest);
        &:hover {
          background-color: var(--color-neutral-button-outlined-hover);
        }
        &:active {
          background-color: var(--color-neutral-button-outlined-press);
        }
        &:disabled {
          background: var(--color-f);
          color: var(--color-neutral-400);
          border: 1px solid var(--color-neutral-300-border);
          cursor: default;
        }
      }
      &.soft {
        background-color: var(--color-neutral-200-darker-bg-light-border);
        color: var(--color-neutral-900-aaa-text-darkest);
        &:hover {
          background-color: var(--color-neutral-button-soft-hover);
        }
        &:active {
          background-color: var(--color-neutral-button-soft-press);
        }
        &:disabled {
          background: var(--color-f);
          color: var(--color-neutral-400);
          cursor: default;
        }
      }
      &.text {
        color: var(--color-neutral-900-aaa-text-darkest);
        &:hover {
          background-color: var(--color-neutral-button-text-hover);
        }
        &:active {
          background-color: var(--color-neutral-button-text-press);
        }
        &:disabled {
          background: var(--color-f);
          color: var(--color-neutral-400);
          cursor: default;
        }
      }
    }
    &.primary,
    &.critical,
    &.promote,
    &.warning,
    &.positive {
      &.filled {
        background-color: var(--color-#{$type}-600-aa-text);
        color: var(--color-f);

        &:hover {
          background-color: var(--color-#{$type}-button-filled-hover);
        }

        &:active {
          background-color: var(--color-#{$type}-button-filled-press);
        }

        &:disabled {
          background: var(--color-neutral-200-darker-bg-light-border);
          color: var(--color-neutral-400);
          cursor: default;
        }
      }

      &.soft {
        background-color: var(--color-#{$type}-200-light-border);
        color: var(--color-#{$type}-700-aaa-colorful);

        &:hover {
          background-color: var(--color-#{$type}-button-soft-hover);
        }

        &:active {
          background-color: var(--color-#{$type}-button-soft-press);
        }

        &:disabled {
          background: var(--color-f);
          color: var(--color-neutral-400);
          cursor: default;
        }
      }

      &.outlined {
        border: 1px solid var(--color-#{$type}-700-aaa-colorful);
        background-color: var(--color-f);
        color: var(--color-#{$type}-700-aaa-colorful);

        &:hover {
          background-color: var(--color-#{$type}-button-outlined-hover);
        }

        &:active {
          background-color: var(--color-#{$type}-button-outlined-press);
        }

        &:disabled {
          background: var(--color-f);
          color: var(--color-neutral-400);
          border: 1px solid var(--color-neutral-300-border);
          cursor: default;
        }
      }

      &.text {
        background-color: var(--color-f);
        color: var(--color-#{$type}-700-aaa-colorful);

        &:hover {
          background-color: var(--color-#{$type}-button-outlined-hover);
        }

        &:active {
          background-color: var(--color-#{$type}-button-outlined-press);
        }

        &:disabled {
          background: var(--color-f);
          color: var(--color-neutral-400);
          cursor: default;
        }
      }
    }
  }
}
.large {
  height: 48px;
}
.medium {
  height: 40px;
}
.small {
  height: 32px;
}
.text-icon-medium {
  height: 20px;
}
.text-icon-small {
  height: 16px;
}
.icon-small {
  padding: 8px;
  & .icon {
    width: 16px;
  }
}
.icon-medium {
  padding: 10px;
  & .icon {
    width: 20px;
  }
}
.icon-large {
  padding: 12px;
  & .icon {
    width: 24px;
  }
}
</style>
